'use strict';
import './rudy.scss';
import Glide from '@glidejs/glide'
import draggable from "vuedraggable";
import CKEditor from '@ckeditor/ckeditor5-vue';
import DecoupledEditor from 'ckeditor5-custom-build';
import SpecialTasksComponent from '../components/SpecialTasksComponent.vue';
import TitlesComponent from '../components/TitlesComponent.vue';
import PowersOfAttorneyComponent from '../components/PowersOfAttorneyComponent.vue';
import JobDescriptionsComponent from '../components/JobDescriptionsComponent.vue';
import ParentalLeavesComponent from '../components/ParentalLeavesComponent.vue';
import CertificateTypeComponent from '../components/CertificateTypeComponent.vue';

const Vue = require('vue')

window.Vue = Vue
window.VueComponents = {
    SpecialTasksComponent,
    TitlesComponent,
    PowersOfAttorneyComponent,
    JobDescriptionsComponent,
    ParentalLeavesComponent,
    CertificateTypeComponent,
}
window.Draggable = draggable
window.CKEditor = CKEditor
window.DecoupledEditor = DecoupledEditor

document.addEventListener('DOMContentLoaded', () => {
    if (document.querySelector('.glide')) {
        new Glide('.glide').mount()
    }
})

const rollUpHeaders = document.querySelectorAll('.rudy-rollup > .rudy-rollup__header');

rollUpHeaders.forEach(header => {
    header.addEventListener('click', () => {
        const panel = header.parentElement;
        panel.classList.toggle('expanded');
    });
});

const typeS = document.getElementById('signature-type');
const signerOne = document.getElementById('txt-signer-one');
const signerTwo = document.getElementById('txt-signer-two');
const superiorLabel = document.getElementById('superior-one-label');
const managerLabel = document.getElementById('hrmanager-label');
const signatureAlign = document.getElementById('signature-align');

hideAllSignatures();
validateSignaturesToShow();
if (typeS) {
    typeS.addEventListener('change', () => {
        hideAllSignatures();
        validateSignaturesToShow();
    });
}

function hideAllSignatures(){
    if(signerOne && signerTwo){
        signerOne.style.display = 'none';
        signerTwo.style.display = 'none';
    }
    if(superiorLabel && managerLabel && signatureAlign){
        superiorLabel.style.display = 'none';
        managerLabel.style.display = 'none';
        signatureAlign.style.display = 'none';
    }
}


function validateSignaturesToShow(){
    if(signerOne) {
        if (typeS.value === 'one-superior-signature') {
            signerOne.style.display = 'block';
            superiorLabel.style.display = 'block';
            signatureAlign.style.display = 'block';
        }
        if (typeS.value === 'two-superior-signatures') {
            signerOne.style.display = 'block';
            signerTwo.style.display = 'block';
            superiorLabel.style.display = 'block';
            signatureAlign.style.display = 'block';
        }

    }
}


