<template>
    <div class="block mb-5">
        <div class="rudy-card">
            <div class="columns">
                <div class="column">
                    <h4 class="rudy-text__subtitle is-4 mb-4">
                        {{ certificate_form_title }}<sup>*</sup>
                    </h4>
                    <hr>
                    <div class="rudy-input__select select is-fullwidth control">
                        <select
                            class="rudy-input__text"
                            name="reason[type]"
                            v-model="certificate_form_selected"
                            required="required"
                        >
                            <option value=""></option>
                            <option
                                v-for="(itemCertificate, indexCertificate) in certificate_forms"
                                :value="indexCertificate"
                            >{{ itemCertificate }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="column">
                    <div v-show="certificate_form_selected">
                        <h4 class="rudy-text__subtitle is-4 mb-4">
                            {{ employment_title }}<sup>*</sup>
                        </h4>
                        <hr>
                        <div class="rudy-input__select select is-fullwidth control">
                            <select
                                class="rudy-input__text"
                                name="position"
                                v-model="employment_selected"
                                required="required"
                                @change="loadDepartments()"
                            >
                                <option value=""></option>
                                <option
                                    v-for="(itemEmployment, indexEmployment) in employments"
                                    :value="indexEmployment"
                                >{{ itemEmployment }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="column">
                    <div v-show="certificate_form_selected && employment_selected">
                        <h4 class="rudy-text__subtitle is-4 mb-4">
                            {{ department_title }}<sup>*</sup>
                        </h4>
                        <hr>
                        <div class="rudy-input__select select is-fullwidth control">
                            <select
                                class="rudy-input__text"
                                name="area"
                                v-model="department_selected"
                                required="required"
                            >
                                <option value=""></option>
                                <option
                                    v-for="(itemDepartment, indexDepartment) in availableDepartments"
                                    :value="indexDepartment"
                                >{{ itemDepartment }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
const props = defineProps({
    certificate_form: Number,
    certificate_forms: Object,
    certificate_form_title: String,
    employment: Number,
    employments: Object,
    employment_title: String,
    department: Number,
    departments: Object,
    department_title: String,
});
const certificate_form_selected = ref(props.certificate_form);
const employment_selected = ref(props.employment);
const department_selected = ref(props.department);
const departmentsClone = JSON.parse(JSON.stringify(props.departments));
let availableDepartments = ref(departmentsClone);
const loadDepartments = () => {
    if (certificate_form_selected.value
        && certificate_form_selected.value !== ''
        && employment_selected.value
        && employment_selected.value !== ''
    ) {
        availableDepartments.value = {};
        const requestOptions = {
            method: 'GET',
            headers: {'Content-Type': 'application/json'},
        };
        fetch('/api/certificates/search-config-availability?' + new URLSearchParams({
            certificateForm: certificate_form_selected.value,
            employmentStatus: employment_selected.value,
        }), requestOptions)
            .then(response => response.json())
            .then(data => {
                if (data.available) {
                    const available = data.available.departments.toString().split(',');
                    Object.keys(departmentsClone).forEach(key => {
                        if (available.indexOf(key.toString()) > -1) {
                            availableDepartments.value[key] = departmentsClone[key];
                        }
                    });
                }
            });
    }
}
loadDepartments();
</script>
