<template>
    <div class="flex flex-row border-gray-500 h-12 bg-zinc-200 px-2 rounded-lg mt-8">
        <button
            :title="help_button.title"
            class="w-10 h-10 px-2 mt-2 text-blue-500 cursor-help"
            :class="{ invisible: help_button.show !== '1' }"
            type="button"
        >
            <span class="fas fa-info-circle" />
        </button>
        <h4 class="rudy-text__subtitle--sm mt-4 mr-4">
            <span>{{ title }}</span>
        </h4>
        <div v-if="projects.length < max_project_inputs" class="flex mt-1 justify-end">
            <button class="rudy-button__primary h-10" type="button" @click="add()">
                <i class="fas fa-plus" aria-hidden="true"></i>
            </button>
        </div>
    </div>

    <div class="flex flex-col gap-4 pt-4 mx-2">
        <div v-for="(project, index) of projects">
            <div class="flex flex-row gap-4">
                <div class="grow">
                    {{ index + 1 }}. {{ title_item }}
                    <textarea
                        :name="`${name}[special_tasks_and_projects][${index}][entry]`"
                        maxlength="200"
                        class="rudy-input__text"
                        v-model="project.entry"
                    ></textarea>
                    <input
                        type="hidden"
                        :name="`${name}[special_tasks_and_projects][${index}][id]`"
                        :value="project.id"
                    />
                </div>

                <div class="w-11 flex flex-col justify-end">
                    <button
                        class="rudy-button rudy-button__secondary h-10 mb-1 w-11"
                        type="button"
                        @click="remove(index)"
                        :disabled="!index"
                    >
                        <i class="fas fa-minus" aria-hidden="true"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>

</template>
<script setup>
const props = defineProps({
    projects: Object,
    title: String,
    title_item: String,
    name: String,
    max_project_inputs: Number,
    help_button: {
        title: String,
        show: Boolean,
    }
});
const remove = (index) => {
    props.projects.splice(index, 1);
}
const add = () => {
    props.projects.push({
        value: '',
        type: null,
    });
}
</script>
