<template>
    <div class="flex flex-row border-gray-500 h-12 bg-zinc-200 px-2 rounded-lg mt-8">
        <button
            :title="help_button.title"
            class="w-10 h-10 px-2 mt-2 text-blue-500 cursor-help"
            :class="{ invisible: help_button.show !== '1' }"
            type="button"
        >
            <span class="fas fa-info-circle" />
        </button>
        <h4 class="rudy-text__subtitle--sm mt-4 mr-4">
            <span>{{ title }}</span>
        </h4>
        <div v-if="powers.length < max_power_attorney" class="flex mt-1 justify-end">
            <button class="rudy-button__primary h-10" type="button" @click="add()">
                <i class="fas fa-plus" aria-hidden="true"></i>
            </button>
        </div>
    </div>

    <div class="flex flex-col gap-4 pt-4 mx-2">
        <div v-for="(powersOfAttorney, index) of powers">
            <div class="flex flex-row gap-4">
                <div class="w-1/4">
                    <div class="flex-col">
                        {{ index + 1 }}. {{ title_item }}
                        <input
                            type="date"
                            class="rudy-input__text"
                            :name="`${name}[${index}][from_date]`"
                            v-model="powersOfAttorney.from_date"
                            :required="powersOfAttorney.powers_of_attorney_entry.description !== ''"
                            :min="from_date"
                            :max="to_date"
                        />
                        <input
                            type="hidden"
                            :name="`${name}[${index}][model]`"
                            :value="model"
                        />
                        <input
                            type="hidden"
                            :name="`${name}[${index}][id]`"
                            :value="powersOfAttorney.id"
                        />
                    </div>
                </div>

                <div class="grow justify-end flex flex-col">
                    <textarea
                        :name="`${name}[${index}][powers_of_attorney_entry][description]`"
                        maxlength="150"
                        class="rudy-input__text"
                        :required="powersOfAttorney.from_date !== ''"
                        v-model="powersOfAttorney.powers_of_attorney_entry.description"
                    ></textarea>
                    <input
                        type="hidden"
                        :name="`${name}[${index}][powers_of_attorney_entry][field]`"
                        value="powers_of_attorney_description"
                    />
                    <input
                        type="hidden"
                        :name="`${name}[${index}][powers_of_attorney_entry][id]`"
                        :value="powersOfAttorney.powers_of_attorney_entry.id"
                    />
                </div>

                <div class="w-11 flex flex-col justify-end">
                    <button
                        class="rudy-button rudy-button__secondary h-10 mb-1 w-11"
                        type="button"
                        @click="remove(index)"
                        :disabled="!index"
                    >
                        <i class="fas fa-minus" aria-hidden="true"></i>
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>
<script setup>
const props = defineProps({
    powers: Object,
    title: String,
    title_item: String,
    name: String,
    model: String,
    max_power_attorney: Number,
    from_date: String,
    to_date: String,
    help_button: {
        title: String,
        show: Boolean,
    }
});
const remove = (index) => {
    props.powers.splice(index, 1);
}
const add = () => {
    props.powers.push({
        from_date: '',
        powers_of_attorney_entry: {
            description: '',
        },
    });
}
</script>
