<template>
    <div class="flex flex-row justify-between border-gray-500 h-12 bg-zinc-200 px-2 rounded-lg mt-4 mb-2">
        <div class="flex flex-row">
            <button
                :title="help_button.title"
                class="w-10 h-10 px-2 mt-2 text-blue-500 cursor-help"
                :class="{ invisible: help_button.show !== '1' }"
                type="button"
            >
                <span class="fas fa-info-circle" />
            </button>
            <h4 class="rudy-text__subtitle--sm mt-4 mr-4">
                <span>{{ title }}<sup>*</sup></span>
            </h4>
            <div v-if="job_descriptions.length < max_job_descriptions" class="flex mt-1 justify-end">
                <button class="rudy-button__primary h-10" type="button" @click="add()">
                    <i class="fas fa-plus" aria-hidden="true"></i>
                </button>
            </div>
        </div>
    </div>

    <ul class="m-0 mb-4 flex flex-col gap-4 mx-2" :set="tmpCurrent = -1">
        <li
            class="list-none flex gap-4"
            v-for="(jobDescription, index) of job_descriptions"
            :set="tmpCurrent += jobDescription.type !== 'subtask' ? 1: 0"
        >
            <input
                type="text"
                :maxlength="jobDescription.type === 'subtask' ? 100: 200"
                :name="`${name}[${index}][value]`"
                class="rudy-input__text"
                :class="{ 'ml-8': jobDescription.type === 'subtask' }"
                v-model="jobDescription.value"
                :required="required"
            />
            <input
                type="hidden"
                :name="`${name}[${index}][type]`"
                :value="jobDescription.type"
            />
            <button
                v-if="(jobDescription.type !== 'subtask')"
                class="rudy-button rudy-button__secondary"
                type="button"
                :disabled="tmpCurrent + 1 < job_descriptions.filter(t => t.type !== 'subtask').length"
                @click="add('subtask')"
            >
                <i class="fas fa-plus" aria-hidden="true"></i>
            </button>
            <button
                class="rudy-button rudy-button__secondary"
                type="button"
                @click="remove(index)"
                :disabled="!index"
            >
                <i class="fas fa-minus" aria-hidden="true"></i>
            </button>
        </li>
    </ul>

</template>
<script setup>
const props = defineProps({
    job_descriptions: Object,
    title: String,
    name: String,
    required: Boolean,
    max_job_descriptions: Number,
    help_button: {
        title: String,
        show: Boolean,
    }
});
const remove = (index) => {
    let subTasks = [];
    let subTaskParent = null;
    Object.keys(props.job_descriptions).forEach(function (item) {
        if (props.job_descriptions[item].type === null) {
            subTaskParent = item == index ? item : null;
        } else if (props.job_descriptions[item].type === 'subtask' && item > index && subTaskParent !== null) {
            subTasks.push(item);
        }
    });
    subTasks.slice().reverse().forEach(s => {
        props.job_descriptions.splice(s, 1);
    });
    props.job_descriptions.splice(index, 1);
}
const add = (type = null) => {
    let obj = {};
    switch (type) {
        case 'subtask':
            obj = {
                value: '',
                type: 'subtask',
            };
            break;
        default:
            obj = {
                value: '',
                type: null
            };
            break;
    }
    props.job_descriptions.push(obj);
}
</script>
