<template>
    <div class="columns flex flex-col">
        <div class="column">
            <p>
                <button
                    :title="help_button.title"
                    class="w-10 h-10 px-2 mt-2 text-blue-500 cursor-help"
                    :class="{ invisible: help_button.show !== '1' }"
                    type="button"
                >
                    <span class="fas fa-info-circle" />
                </button>
                <span>{{ title }}?</span>
            </p>
            <input
                type="checkbox"
                :checked="parental_leaves.has_parental_leaves"
                @change="updateParentalLeave()"
                class="ml-4 peer"
            />
            <br /><br />
            <div class="flex flex-col gap-4" v-show="parental_leaves.has_parental_leaves === true">
                <div>{{ subtitle }}?</div>

                <div class="columns">
                    <div class="column">
                        <span>{{ from_label }}<sup>*</sup></span>
                        <input
                            type="date"
                            :name="`${name}[from_date]`"
                            class="rudy-input__text"
                            v-model="parental_leaves.from_date"
                            :required="parental_leaves.has_parental_leaves"
                            :min="from_date"
                            :max="to_date"
                        />
                    </div>
                    <div class="column">
                        <span>{{ to_label }}<sup>*</sup></span>
                        <input
                            type="date"
                            :name="`${name}[to_date]`"
                            class="rudy-input__text"
                            v-model="parental_leaves.to_date"
                            :required="parental_leaves.has_parental_leaves"
                            :min="parental_leaves.from_date"
                            :max="to_date"
                        />
                    </div>
                    <input
                        type="hidden"
                        :name="`${name}[model]`"
                        :value="model"
                    />
                    <input
                        type="hidden"
                        :name="`${name}[id]`"
                        :value="parental_leaves.id"
                    />
                </div>
            </div>

        </div>
    </div>
</template>
<script setup>
const props = defineProps({
    title: String,
    subtitle: String,
    from_label: String,
    to_label: String,
    name: String,
    from_date: String,
    to_date: String,
    model: String,
    parental_leaves: {
        has_parental_leaves: Boolean,
        id: String,
        from_date: String,
        to_date: String,
    },
    help_button: {
        title: String,
        show: Boolean,
    }
});
const updateParentalLeave = () => {
    props.parental_leaves.has_parental_leaves = !props.parental_leaves.has_parental_leaves;
    if (!props.parental_leaves.has_parental_leaves) {
        props.parental_leaves.from_date = '';
        props.parental_leaves.to_date = '';
    }
}
</script>
